import {createI18n} from "vue-i18n";
import messages from "@/i18n";

const preferredLanguage = localStorage.getItem('preferredLanguage');

const i18n = createI18n({
  locale: preferredLanguage || navigator.language,
  fallbackLocale: 'en',
  silentFallbackWarn: true,
  fallbackRoot: true,
  silentTranslationWarn: true,
  messages,
})

export { i18n };
