const hexToRgb  = function(h: string, exportValuesOnly = false): string | Array<number> {
  let r = '0', g = '0', b = '0';
  const hex = h.split("");

  if (hex.length == 4) {
    r = "0x" + hex[1] + hex[1];
    g = "0x" + hex[2] + hex[2];
    b = "0x" + hex[3] + hex[3];

  } else if (hex.length == 7) {
    r = "0x" + hex[1] + hex[2];
    g = "0x" + hex[3] + hex[4];
    b = "0x" + hex[5] + hex[6];
  }

  if (exportValuesOnly) {
    return [parseInt(r), parseInt(g) , parseInt(b)];
  }

  return +r + "," + +g + "," + +b;
}

const lighten = function (rgb: Array<number>, percentage): Array<number> {
  return [
    Math.min(rgb[0]+percentage*255, 255),
    Math.min(rgb[1]+percentage*255, 255),
    Math.min(rgb[2]+percentage*255, 255)
  ]
}

const darken = function (rgb: Array<number>, percentage): Array<number> {
  return [
    Math.max(rgb[0]+percentage*255, 0),
    Math.max(rgb[1]+percentage*255, 0),
    Math.max(rgb[2]+percentage*255, 0)
  ]
}

const shade = function (h: string, percentage: number): string {
  const rgb = hexToRgb(h, true) as Array<number>;

  let modifiedRgb;

  if (percentage >= 0) {
    modifiedRgb = lighten(rgb, percentage);
  } else {
    modifiedRgb = darken(rgb, percentage);
  }

  const r = (+modifiedRgb[0]).toString(16);
  const g = (+modifiedRgb[1]).toString(16);
  const b = (+modifiedRgb[2]).toString(16);

  return "#" + r.padStart(2,"0") + g.padStart(2, "0") + b.padStart(2, "0");
}

export function defineColorVar(varName: string, color: string) {
  const root = document.documentElement;
  root.style.setProperty(`--ion-color-${varName}`, color);
  root.style.setProperty(`--ion-color-${varName}-rgb`, hexToRgb(color) as string);
  root.style.setProperty(`--ion-color-${varName}-contrast`, '#FFF');
  root.style.setProperty(`--ion-color-${varName}-contrast-rgb`, '255, 255, 255');
  root.style.setProperty(`--ion-color-${varName}-shade`, shade(color, 0.2));
  root.style.setProperty(`--ion-color-${varName}-tint`, shade(color, -0.2));
}