/* eslint-disable no-console */

import {register} from 'register-service-worker'
import {alertController} from "@ionic/vue";
import {i18n} from '@/i18n-init';

const $t = i18n.global.t;

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered() {
      console.log('Service worker has been registered.')
    },
    cached() {
      console.log('Content has been cached for offline use.')
    },
    updatefound() {
      console.log('New content is downloading.')
    },
    updated() {
      console.log('New content is available; please refresh.');

      // currently disable this alert due to it being triggered even when most recent version was loaded
      /* alertController
        .create({
          header: $t('misc.newContentsHeader'),
          message: $t('misc.newContentsBody'),
          buttons: [
            {
              text: $t('misc.cancel'),
              role: 'cancel',
              cssClass: 'secondary',
            },
            {
              text: 'Okay',
              handler: async () => {
                window.location.reload(true);
              },
            },
          ],
        })
        .then(alert => (alert.present()))
        .catch(e => {
          console.error(e);
        }); */
    },
    offline() {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error(error) {
      console.error('Error during service worker registration:', error)
    }
  })
}
