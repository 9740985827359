export default {
  de: {
    misc: {
      cancel: 'Abbrechen',
      '404': 'Entschuldigung!\n\n Wir konnten diese Ressource leider nicht (mehr) auffinden.\n\n Gegebenenfalls hilft es, die App mit aktiver' +
        ' Internetverbindung erneut zu laden und/oder einen Navigationsschritt zurück zu gehen.',
      newContentsHeader: 'Aktualisierung ausstehend',
      newContentsBody: 'Laden Sie die App jetzt neu, um den Inhalt der aktuellsten Version anzuzeigen!',
      missingNetworkHeader: 'Keine Internetverbindung',
      missingNetworkBody: 'Bitte rufen Sie diesen Inhalt mindestens einmal mit aktiver Internetverbindung auf.',
      changeLanguage: {
        title: 'Sprache Wechseln?',
        body: 'Durch das Umstellen der Sprache werden die aktuell verfügbaren Offline-Daten möglicherweise unbrauchbar und müssen neu heruntergeladen werden. Möchten Sie fortfahren?'
      },
      installAppButton: 'App installieren',
      installAlert: {
        header: 'App installieren?',
        textBody:  'Möchten Sie {appName} zu Ihrem Startbildshirm hinzufügen?',
        appNameFallback: 'diese App',
        dismiss: 'Nein, danke',
        approve: 'Installieren',
      },
      installNotificationIos: {
        headline: '{appName} installieren?',
        appNameFallback: 'Diese App',
        instructionStart: 'Tippen Sie',
        instructionEnd: 'und wählen Sie "zum Home-Screen hinzufügen"'
      }
    },
    header: {
      logoLabel: 'Zur Tourenauswahl',
      openMenu: 'Hauptmenü öffnen'
    },
    menu: {
      title: 'Menü',
      accessibility: 'Barrierefreiheit',
      privacy: 'Datenschutz',
      tourOverview: 'Tourenübersicht',
      imprint: 'Impressum',
      removeOfflineData: 'Offlinedaten entfernen',
      language: {
        label: 'Sprache',
        en: 'Englisch',
        de: 'Deutsch',
      }
    },
    regionTrailer: {
      skip: 'Video überspringen',
      signLanguage: 'Gebärdensprache',
      easyLanguage: 'Einfache Sprache',
      defaultLanguage: 'Zurück',
    },
    tourOverview: {
      mobilityHeadline: 'Mobilität',
      audioFeatureHeadline: 'Tour Audio Feature',
      musicFeatureHeadline: 'Musik',
      tourTrailerHeader: 'Tour Trailer',
      gastroFeatureHeader: 'Gastronomie',
      menuLabel: 'Orte Menü',
      noToursAvailable: 'Für dieses Erlebnisportal sind zur Zeit keine Touren verfügbar. Bitte versuchen Sie es später noch einmal.',
    },
    placeDetails: {
      placeTrailerHeader: 'Ort Trailer',
      travelInfoHeader: 'Anreiseempfehlung',
      audioFeatureHeadline: 'Hörspaziergang',
      accessibilityInfoHeader: 'Informationen zur Barrierefreiheit',
      noAccessibilityInfoText: 'Es liegen keine geprüften Informationen zur Barrierefreiheit vor.',
      noPlaceDataAvailable: 'Über diesen Ort sind zur Zeit leider keine weiteren Informationen verfügbar. Bitte versuchen Sie es später noch einmal.'
    },
    components: {
      text: {
        prevItem: 'Zurück',
        nextItem: 'Nächster Tipp'
      },
      offlineMode: {
        headline: 'Offline Modus',
        deleteDataAlertBody: 'Mit dieser Aktion werden Offlinedaten für Touren vom Gerät entfernt.',
        deleteDataAlertNoMediaBody: 'Es sind keine Offlinedaten für Touren hinterlegt. Touren können in der Tourübersicht Offline verfügbar gemacht werden.',
        downloadMedia: 'Tour herunterladen',
        mediaNotAvailable: 'Diese Tour ist nicht offline verfügbar! Um sie auch ohne Internetverbindung einsehen zu können, bitte "Tour herunterladen"' +
          ' betätigen.',
        mediaAvailable: 'Diese Tour ist offline verfügbar!',
        mediaPartiallyAvailable: 'Diese Tour ist teilweise offline verfügbar. Um sie vollständig ohne Internetverbindung einsehen zu können, bitte ' +
          '"Tour herunterladen" betätigen.',
        incompleteDownload: 'Download unvollständig',
        incompleteMediaDLBody: 'Die Tour konnte nicht vollständig heruntergeladen werden. Bitte  später erneut versuchen.',
      },
      openingHours: {
        title: 'Öffnungszeiten',
        showDetails: 'Details zu den Öffnungszeiten anzeigen',
        futureTitle: 'Zukünftig',
        specialTitle: 'Davon abweichend',
        closed: 'geschlossen',
        opened: 'geöffnet',
        validFrom: 'Gültig ab:',
        validThrough: 'bis:',
        currentlyOpened: 'Derzeit geöffnet',
        currentlyClosed: 'Derzeit geschlossen',
        time: ' Uhr',
        dayOfWeek: {
          monday: 'Montag',
          tuesday: 'Dienstag',
          wednesday: 'Mittwoch',
          thursday: 'Donnerstag',
          friday: 'Freitag',
          saturday: 'Samstag',
          sunday: 'Sonntag',
          publicHolidays: 'Gesetzliche Feiertage',
        },
      },
      locationInformation: {
        visitWebsite: 'Webseite besuchen',
        travelByTrain: 'Anreise mit öffentlichen Verkehrsmitteln',
        map: 'Link zur Karte',
        travelByCar: 'Anreise mit dem Auto',
      },
      audioPlayer: {
        chapterOverview: 'Kapitelübersicht',
        chapterPlaceholder: 'Kapitel',
        showTranscript: 'Transkript',
        transcriptGoBack: 'Zurück',
        transcriptTitle: 'Transkript',
        nextChapter: 'Nächstes Kapitel',
        prevChapter: 'Vorheriges Kapitel',
        playPause:'Abspielen oder Pausieren',
        progress: 'Aktuelle Spieldauer',
        openTranscript: 'Transkript öffnen',
        showChapters: 'Kapitelübersicht öffnen'
      },
      visitorInformation: {
        title: 'Besucherinformationen',
        openSection: "Bereich {section} anzeigen oder verbergen",
        photography: {
          label: 'Fotografieren',
        },
        gastro: {
          label: 'Gastronomie',
        },
        sanitation: {
          label: 'Sanitär',
        },
        otherService: {
          label: 'Service',
        },
        digitalOffer: {
          label: 'Digitales Angebot',
        },
        guidedTour: {
          label: 'Führung',
        },
        entrance: {
          label: 'Weitere Hinweise',
        }
      },
      contentSlider: {
        previous: 'Vorheriges Bild',
        next: 'Nächstes Bild'
      }
    }
  },
  en: {
    misc: {
      cancel: 'Cancel',
      '404': 'Sorry!\n\n Unfortunately, we could not find this resource (anymore).\n\n It may help to reload this app with an active' +
        ' Internet connection and/or to go back one navigation step.',
      newContentsHeader: 'Update pending',
      newContentsBody: 'Reload app now to display the content of the latest version!',
      missingNetworkHeader: 'No internet connection',
      missingNetworkBody: 'Please access this content at least once with an active internet connection.',
      changeLanguage: {
        title: 'Change Language',
        body: 'Changing the language may render the currently available offline data unusable and it will need to be re-downloaded. Do you want to continue?'
      },
      installAppButton: 'Install App',
      installAlert: {
        header: 'Install App?',
        textBody:  'Do you want to add {appName} to your home screen?',
        appNameFallback: 'this app',
        dismiss: 'No, thanks',
        approve: 'Install',
      },
      installNotificationIos: {
        headline: 'Install {appName}?',
        appNameFallback: 'this app',
        instructionStart: 'Tap',
        instructionEnd: 'and select "Add to Home-Screen"'
      }
    },
    header: {
      logoLabel: 'Go to tour selection',
      openMenu: 'Open main navigation'
    },
    menu: {
      title: 'Menu',
      accessibility: 'Accessiblity',
      privacy: 'Data privacy',
      tourOverview: 'Tour overview',
      imprint: 'Imprint',
      removeOfflineData: 'Remove offline data',
      language: {
        label: 'Language',
        en: 'English',
        de: 'German',
      }
    },
    regionTrailer: {
      skip: 'Skip video',
      signLanguage: 'Sign language',
      easyLanguage: 'Easy language',
      defaultLanguage: 'Back',
    },
    tourOverview: {
      mobilityHeadline: 'Mobility',
      audioFeatureHeadline: 'Tour Audio Feature',
      musicFeatureHeadline: 'Music',
      tourTrailerHeader: 'Tour Trailer',
      gastroFeatureHeader: 'Gastronomy',
      menuLabel: 'Places navigation',
      noToursAvailable: 'There are currently no tours available for this portal. Please try again later.'
    },
    placeDetails: {
      placeTrailerHeader: 'Place trailer',
      travelInfoHeader: 'Travel info',
      audioFeatureHeadline: 'Audiowalk',
      accessibilityInfoHeader: 'Accessibility info',
      noAccessibilityInfoText: 'There are no verified details about accessibility available',
      noPlaceDataAvailable: 'Sorry, there is no more information available about this place at the moment. Please try again later.'
    },
    components: {
      text: {
        prevItem: 'Back',
        nextItem: 'Next tip'
      },
      offlineMode: {
        headline: 'Offline mode',
        deleteDataAlertBody: 'This action will remove offline data for tours from this device.',
        deleteDataAlertNoMediaBody: 'There is no offline data for tours on this device. Tours can be made available for offline use via the tour menu.',
        downloadMedia: 'Download tour',
        mediaNotAvailable: 'This tour is not available offline! To view it without an internet connection, please click \'Download tour\'.',
        mediaAvailable: 'This tour is available offline!',
        mediaPartiallyAvailable: 'This tour is partially available offline. To view it in full without internet connection, please press \'Download tour\'.',
        incompleteDownload: 'Download incomplete',
        incompleteMediaDLBody: 'The tour could not be downloaded completely. Please try again later.',
      },
      openingHours: {
        title: 'Opening hours',
        showDetails: 'Show details for opening hours',
        futureTitle: 'In the future',
        specialTitle: 'Differing from this',
        closed: 'closed',
        opened: 'opened',
        validFrom: 'Valid from:',
        validThrough: 'through:',
        currentlyOpened: 'currently opened',
        currentlyClosed: 'currently closed',
        time: '',
        dayOfWeek: {
          monday: 'Monday',
          tuesday: 'Tuesday',
          wednesday: 'Wednesday',
          thursday: 'Thursday',
          friday: 'Friday',
          saturday: 'Saturday',
          sunday: 'Sunday',
          publicHolidays: 'Public Holidays',
        },
      },
      locationInformation: {
        visitWebsite: 'Visit website',
        travelByTrain: 'Travel by public transport',
        travelByCar: 'Travel by car',
        map: 'Link to map',
      },
      audioPlayer: {
        chapterOverview: 'Chapter overview',
        chapterPlaceholder: 'Chapter',
        showTranscript: 'Transcript',
        transcriptGoBack: 'Back',
        transcriptTitle: 'Transcript',
        nextChapter: 'Next Chapter',
        prevChapter: 'Previous Chapter',
        playPause: 'Play or pause',
        progress: 'Current time progression',
        openTranscript: 'Open transcript',
        showChapters: 'Show chapter overview'
      },
      visitorInformation: {
        title: 'Visitor information',
        openSection: "Show or hide section {section}",
        photography: {
          label: 'Photography',
        },
        gastro: {
          label: 'Gastronomy',
        },
        sanitation: {
          label: 'Sanitation',
        },
        otherService: {
          label: 'Service',
        },
        digitalOffer: {
          label: 'Digital offer',
        },
        guidedTour: {
          label: 'Guided Tour',
        },
        entrance: {
          label: 'Further notes',
        }
      },
      contentSlider: {
        previous: 'Previous Image',
        next: 'Next Image'
      }
    }
  }
}
