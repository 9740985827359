
import {
  IonApp,
  IonButton,
  IonContent,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonRouterOutlet,
  IonSplitPane,
  IonFooter,
  IonIcon,
  menuController,
  alertController,
  getPlatforms,
} from '@ionic/vue';
import {defineComponent} from 'vue';
import {mapMutations, mapState} from "vuex";
import {AppState} from "@/store-management/vuex";
import {BlobContent} from "@/store-management/app-db";
import {defineColorVar} from "@/lib/color-functions";
import {closeOutline, gitBranchOutline, exitOutline} from 'ionicons/icons';
declare const APP_VERSION: any; // provided by webpack

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonButton,
    IonContent,
    IonItem,
    IonLabel,
    IonList,
    IonMenu,
    IonRouterOutlet,
    IonSplitPane,
    IonFooter,
    IonIcon,
  },
  data() {
    return {
      version: APP_VERSION,
      gitIcon: gitBranchOutline,
      closeIcon: closeOutline,
      addHome: exitOutline,
      availableLanguages: ['de', 'en'],
      pwaState: {
        deferredPrompt: null,
        isInstalled: false,
        alertDismissed: false,
        showIosHint: false,
        iosHintDismissed: false,
      }
    }
  },

  created() {
    window.addEventListener('beforeinstallprompt', (e) => {
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      e.preventDefault();
      this.pwaState.deferredPrompt = e;
    });

    window.addEventListener('appinstalled', () => {
      this.pwaState.isInstalled = true;
    });

    if (this.getDisplayMode() === 'standalone') {
      this.pwaState.isInstalled = true;
    }
  },

  computed: {
    tourId(): string {
      return this.$route.params.tour?.toString();
    },
    ...mapState({
      regionData(state: AppState) {
        return state.region || null;
      },
      tourData(state: AppState) {
        return state.tours[this.tourId] || null;
      }
    }),
    ...mapState(['regionId']),
    ...mapState(['routeCounter']),
  },
  methods: {
    ...mapMutations(['emptyAvailableOfflineMedia']),
    async menuToggle() {
      // this.selectedIndex = index
      await menuController.close('main-navigation');
    },

    getDisplayMode() {
      const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
      if (document.referrer.startsWith('android-app://') || isStandalone) {
        return 'standalone';
      }
      if ('standalone' in navigator) {
        if (navigator['standalone']) {
          return 'standalone'
        }
      }
      return 'browser';
    },

    async showAddToHomeAlert() {
      const alert = await alertController.create({
        cssClass: 'pwa-install-alert',
        header: this.$t('misc.installAlert.header'),
        message: this.$t('misc.installAlert.textBody', {
          appName: this.regionData
              ? '<strong>'+this.regionData['schema:name']+'</strong>'
              : this.$t('misc.installAlert.appNameFallback'),
        }),
        buttons: [
          {
            text: this.$t('misc.installAlert.dismiss'),
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              this.pwaState.alertDismissed = true;
            }
          },
          {
            text: this.$t('misc.installAlert.approve'),
            cssClass: 'primary button-native',
            handler: this.addToHome,
          },
        ],
      });

      await alert.present();
    },

    addToHome() {
      this.pwaState.deferredPrompt.prompt();
      this.pwaState.deferredPrompt.userChoice
        .then((choiceResult) => {
          if (choiceResult.outcome === 'accepted') {
            this.pwaState.deferredPrompt = null;
          }
        })
        .then(() => {
          this.pwaState.alertDismissed = true;
        });
    },

    async setLanguage(locale) {
      const alert = await alertController
        .create({
          header: this.$t('misc.changeLanguage.title'),
          message: this.$t('misc.changeLanguage.body'),
          buttons: [
            {
              text: this.$t('misc.cancel'),
              role: 'cancel',
              cssClass: 'secondary',
            },
            {
              text: 'Okay',
              handler: async () => {
                localStorage.setItem('preferredLanguage', locale);
                this.$router.go(0);
              },
            },
          ],
        });
      return alert.present();
    },

    async confirmOfflineDataRemove() {
      let alert;
      if (!this.regionId || !(await BlobContent.hasOfflineData(this.regionId))) {
        alert = await alertController
            .create({
              header: this.$t('menu.removeOfflineData'),
              message: this.$t('components.offlineMode.deleteDataAlertNoMediaBody'),
              buttons: [
                {
                  text: 'Okay',
                },
              ],
            });
      } else {
        alert = await alertController
            .create({
              header: this.$t('menu.removeOfflineData'),
              message: this.$t('components.offlineMode.deleteDataAlertBody'),
              buttons: [
                {
                  text: this.$t('misc.cancel'),
                  role: 'cancel',
                  cssClass: 'secondary',
                },
                {
                  text: 'Okay',
                  handler: async () => {
                    await BlobContent.removeBlobContent(this.regionId);
                    this.emptyAvailableOfflineMedia({regionId: this.regionId});
                  },
                },
              ],
            });
      }
      return alert.present();
    }
  },
  watch: {
    'routeCounter': {
      handler(count) {
        if ( this.getDisplayMode() !== 'standalone') {
          if (this.pwaState.deferredPrompt) {
            // awesome pwa mode
            if (count >= 2 && !this.pwaState.alertDismissed) {
              this.showAddToHomeAlert();
            }
          } else {
            // yuck...
            const platForms = getPlatforms();

            if (count >= 2 && platForms.includes('ios') && !this.pwaState.iosHintDismissed) {
              this.pwaState.showIosHint = true;
            }
            // auto dismiss after 2 page changes
            if (count > 3 && !this.pwaState.iosHintDismissed) {
              this.pwaState.showIosHint = true;
              this.pwaState.iosHintDismissed = true;
            }
          }
        }
      }
    },

    'regionData': {
      immediate: true,
      deep: true,
      handler(newData) {
        if (!newData || !newData["epapp:primaryColor"]) {
          return;
        }
        defineColorVar('primary', newData["epapp:primaryColor"]);
      }
    },

    'tourData': {
      immediate: true,
      deep: true,
      handler(newData) {
        if (!newData || !newData["epapp:primaryColor"]) {
          return;
        }
        defineColorVar('tour', newData["epapp:primaryColor"]);
      }
    }
  }

});
