import {db} from './indexed-db';

export class BlobContent {
  private static idb = db; // indexed-db
  private static mediaSelectionRegex = /\.(mp3|mp4|svg|jpe?g|png)$/i;

  private static async dbGetBlob(region: string, url: string) {
    const blobContent = await this.idb.blobContent.get({region, url});
    if (!blobContent || !blobContent.data) {
      throw new Error('Not found');
    }
    return blobContent.data;
  }

  public static async addBlobContent(region: string, url: string, signal?: AbortSignal) {
    const remoteBlob = await fetch(url, {signal}).then(res => (res.blob()));
    await this.idb.blobContent.put({region, url, data: remoteBlob});
    return this.getBlobContent(region, url);
  }

  public static async getBlobContent(region: string, url: string): Promise<string> {
    try {
      const blob = await this.dbGetBlob(region, url);
      return window.URL.createObjectURL(blob);
    } catch {
      return url;
    }
  }

  public static async listBlobContents(region: string): Promise<string[]> {
    const contents = await this.idb.blobContent.filter(content => (content.region === region)).toArray();
    return contents.map(content => (content.url));
  }

  public static async hasOfflineData(region: string): Promise<boolean> {
    return !!(await this.idb.blobContent.filter(content => (content.region === region)).count());
  }

  public static removeBlobContent(region: string, url?: string) {
    if (url === undefined) {
      return this.idb.blobContent.filter(content => (content.region === region)).delete();
    }
    return this.idb.blobContent.where('[region+url]').equals([region, url]).delete();
  }
}
