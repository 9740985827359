import {createRouter, createWebHistory} from '@ionic/vue-router';
import {RouteRecordRaw} from 'vue-router';
import {store} from '@/store-management/vuex';
import {alertController} from "@ionic/vue";
import {i18n} from "@/i18n-init";

const [START_REGION] = location.hostname.split('.');

const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    redirect: `/regions/${START_REGION}`
  },
  {
    path: '/regions/404',
    component: () => import ('../views/404.vue')
  },
  {
    path: '/regions/:region',
    component: () => import ('../views/regions/region.vue')
  },
  {
    path: '/regions/:region/easy-language',
    component: () => import ('../views/regions/easy-language.vue')
  },
  {
    path: '/regions/:region/sign-language',
    component: () => import ('../views/regions/sign-language.vue')
  },
  {
    path: '/regions/:region/imprint',
    component: () => import ('../views/imprint.vue')
  },
  {
    path: '/regions/:region/privacy',
    component: () => import ('../views/privacy.vue')
  },
  {
    path: '/regions/:region/accessibility',
    component: () => import ('../views/accessibility.vue')
  },
  {
    path: '/regions/:region/tours',
    component: () => import ('../views/regions/tours/ov-tours.vue')
  },
  {
    path: '/regions/:region/tours/404',
    component: () => import ('../views/404.vue')
  },
  {
    path: '/regions/:region/tours/:tour',
    component: () => import ('../views/regions/tours/tour.vue')
  },
  {
    path: '/regions/:region/tours/:tour/:place',
    component: () => import ('../views/regions/tours/places/place.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

const $t = i18n.global.t;
const missingNetwork = () => {
  return alertController
    .create({
      header: $t('misc.missingNetworkHeader'),
      message: $t('misc.missingNetworkBody'),
      buttons: [
        {
          text: $t('misc.cancel'),
          role: 'cancel',
          cssClass: 'secondary',
        },
        {
          text: 'Okay',
          handler: async () => {
            window.location.href = '/';
          },
        },
      ],
    })
    .then(alert => (alert.present()))
    .catch(e => {
      console.error(e);
    });
};

router.beforeEach((to) => {
  if (to.params?.region) {
    if (!store.state.region || store.state.region.id !== to.params.region) {
      store.commit('setRegionId', to.params.region);
      store.dispatch({
        type: 'getRegionData',
        regionId: to.params.region
      })
        .catch(e => {
          if (e && e.message === 'OFFLINE') {
            missingNetwork();
          }
          throw e;
        });
    }
  }
  if (to.params?.tour) {
    if (!Object.prototype.hasOwnProperty.call(store.state.tours, to.params.tour?.toString())) {
      store
        .dispatch({
          type: 'getTourData',
          tourId: to.params.tour
        })
        .catch(e => {
          if (e && e.message === 'OFFLINE') {
            missingNetwork();
          }
          throw e;
        });
    }
  }
  return true;
});

router.afterEach(() => {
  store.commit('updateRoute');
});

export default router;
