import Dexie from 'dexie';

export class EPDB extends Dexie {
  blobContent: Dexie.Table<BlobContent, string>;

  constructor() {
    super('EPDB');
    this.version(1).stores({
      blobContent: '[region+url]',
    });
    this.blobContent = this.table('blobContent');
  }
}

export interface BlobContent {
  region: string;
  url: string;
  data: Blob;
}

export const db = new EPDB();
