<template>
  <IonApp>
    <IonSplitPane content-id="main-content" id="ttg-pwa" when="md">
      <ion-menu content-id="main-content"
                type="overlay"
                menu-id="main-navigation"
                id="main-navigation"
                role="navigation"
                :aria-label="$t('menu.title')">

        <ion-content class="ion-padding-top">
          <ion-list v-if="regionData && regionData['epapp:tours'] && regionData['epapp:tours'].length"
                    lines="none" class="navigation-block tours ion-margin-bottom"  role="menu">
            <ion-item :router-link="`/regions/${regionData.id}/tours`"
                      @click="menuToggle()"
                      role="menuitem"
                      :aria-label="$t('menu.tourOverview')"
                      :class="['list-header',
                        { selected: $route.path === `/regions/${regionData.id}/tours` }
                      ]">
              <ion-label class="nav-level-1">{{ $t('menu.tourOverview') }}</ion-label>
            </ion-item>
            <ion-item>
              <ion-list lines="none" role="menu">
                <ion-item v-for="tour in regionData['epapp:tours']"
                          :router-link="`/regions/${regionData.id}/tours/${tour['@id']}`"
                          @click="menuToggle()"
                          role="menuitem"
                          :aria-label="tour['schema:name']"
                          :key="tour['@id']"
                          :class="{ selected: $route.path === `/regions/${regionData.id}/tours/${tour['@id']}` }"
                          router-direction="root"
                          lines="none"
                          detail="false">
                  <ion-label class="nav-level-2">{{ tour['schema:name'] }}</ion-label>
                </ion-item>
              </ion-list>
            </ion-item>
          </ion-list>

          <ion-list v-if="regionData && regionData['epapp:accessibilityDescription']"
                    lines="none"  class="navigation-block ion-margin-bottom" role="menu">
            <ion-item :router-link="`/regions/${regionData.id}/accessibility`"
                      @click="menuToggle()"
                      role="menuitem"
                      aria-label="$t('menu.accessibility')"
                      :class="{ selected: $route.path === '/accessibility' }">
              <ion-label class="nav-level-1">{{ $t('menu.accessibility') }}</ion-label>
            </ion-item>
          </ion-list>

          <ion-list v-if="regionData" lines="none" class="navigation-block ion-margin-bottom" role="menu">
            <ion-item @click="setLanguage('en')"
                      role="menuitem"
                      :aria-label="$t('menu.language.label')">
              <ion-label class="nav-level-1">{{ $t('menu.language.label') }}</ion-label>
            </ion-item>
            <ion-item>
              <ion-list lines="none" role="menu">
                <template v-for="locale in availableLanguages">
                  <ion-item v-if="locale !== $root.$i18n.locale.split('-')[0]"
                            @click="setLanguage(locale)"
                            role="menuitem"
                            :aria-label="$t(`menu.language.${locale}`)"
                            :key="'language_'+locale"
                            lines="none"
                            detail="false">
                    <ion-label class="nav-level-2">{{ $t(`menu.language.${locale}`) }}</ion-label>
                  </ion-item>
                </template>
              </ion-list>
            </ion-item>
          </ion-list>

          <ion-list v-if="regionData" lines="none" class="navigation-block ion-margin-bottom" role="menu">
            <ion-item @click="confirmOfflineDataRemove()"
                      role="menuitem"
                      :aria-label="$t('menu.removeOfflineData')">
              <ion-label class="nav-level-1">{{ $t('menu.removeOfflineData') }}</ion-label>
            </ion-item>
          </ion-list>

          <ion-list v-if="regionData && (regionData['epapp:imprint'] || regionData['epapp:dataPrivacy'])"
                    lines="none" class="navigation-block ion-margin-bottom" role="menu">
            <ion-item v-if="regionData['epapp:imprint']"
                      :router-link="`/regions/${regionData.id}/imprint`"
                      @click="menuToggle()"
                      role="menuitem"
                      :aria-label="$t('menu.imprint')"
                      :class="{ selected: $route.path === '/imprint' }">
              <ion-label class="nav-level-1">{{ $t('menu.imprint') }}</ion-label>
            </ion-item>
            <ion-item v-if="regionData['epapp:dataPrivacy']"
                      :router-link="`/regions/${regionData.id}/privacy`"
                      @click="menuToggle()"
                      role="menuitem"
                      :aria-label="$t('menu.privacy')"
                      :class="{ selected: $route.path === '/privacy' }">
              <ion-label class="nav-level-1">{{ $t('menu.privacy') }}</ion-label>
            </ion-item>

          </ion-list>

          <ion-button v-if="pwaState.deferredPrompt && !pwaState.isInstalled"
                      @click="addToHome()" class="ion-margin-start"
          >{{ $t('misc.installAppButton') }}</ion-button>
        </ion-content>

        <ion-footer>
          <div class="ion-padding version">
            <ion-icon name="version" :ios="gitIcon" :md="gitIcon"></ion-icon>
            <span>{{version}}</span>
          </div>
        </ion-footer>
      </ion-menu>
      <ion-router-outlet id="main-content"></ion-router-outlet>
    </IonSplitPane>
  </IonApp>

  <div class="pwa-install-alert-ios" v-if="pwaState.showIosHint && !pwaState.iosHintDismissed">
    <div class="content">
      <div class="dismiss" @click="pwaState.iosHintDismissed = true">
        <ion-icon name="close-outline" :ios="closeIcon" :md="closeIcon"></ion-icon>
      </div>
      <p class="ion-no-margin ion-padding-end">
        <strong>
          {{ $t('misc.installNotificationIos.headline', {
            appName: regionData
                ? regionData['schema:name']
                : $t('misc.installNotificationIos.appNameFallback')
          }) }}
        </strong>
      </p>
      <p class="ion-no-margin ion-padding-top">
        <span>{{ $t('misc.installNotificationIos.instructionStart') }}</span>
        <ion-icon class="add-home" name="add-home" :ios="addHome" :md="addHome"></ion-icon>
        <span>{{ $t('misc.installNotificationIos.instructionEnd') }}</span>
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import {
  IonApp,
  IonButton,
  IonContent,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonRouterOutlet,
  IonSplitPane,
  IonFooter,
  IonIcon,
  menuController,
  alertController,
  getPlatforms,
} from '@ionic/vue';
import {defineComponent} from 'vue';
import {mapMutations, mapState} from "vuex";
import {AppState} from "@/store-management/vuex";
import {BlobContent} from "@/store-management/app-db";
import {defineColorVar} from "@/lib/color-functions";
import {closeOutline, gitBranchOutline, exitOutline} from 'ionicons/icons';
declare const APP_VERSION: any; // provided by webpack

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonButton,
    IonContent,
    IonItem,
    IonLabel,
    IonList,
    IonMenu,
    IonRouterOutlet,
    IonSplitPane,
    IonFooter,
    IonIcon,
  },
  data() {
    return {
      version: APP_VERSION,
      gitIcon: gitBranchOutline,
      closeIcon: closeOutline,
      addHome: exitOutline,
      availableLanguages: ['de', 'en'],
      pwaState: {
        deferredPrompt: null,
        isInstalled: false,
        alertDismissed: false,
        showIosHint: false,
        iosHintDismissed: false,
      }
    }
  },

  created() {
    window.addEventListener('beforeinstallprompt', (e) => {
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      e.preventDefault();
      this.pwaState.deferredPrompt = e;
    });

    window.addEventListener('appinstalled', () => {
      this.pwaState.isInstalled = true;
    });

    if (this.getDisplayMode() === 'standalone') {
      this.pwaState.isInstalled = true;
    }
  },

  computed: {
    tourId(): string {
      return this.$route.params.tour?.toString();
    },
    ...mapState({
      regionData(state: AppState) {
        return state.region || null;
      },
      tourData(state: AppState) {
        return state.tours[this.tourId] || null;
      }
    }),
    ...mapState(['regionId']),
    ...mapState(['routeCounter']),
  },
  methods: {
    ...mapMutations(['emptyAvailableOfflineMedia']),
    async menuToggle() {
      // this.selectedIndex = index
      await menuController.close('main-navigation');
    },

    getDisplayMode() {
      const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
      if (document.referrer.startsWith('android-app://') || isStandalone) {
        return 'standalone';
      }
      if ('standalone' in navigator) {
        if (navigator['standalone']) {
          return 'standalone'
        }
      }
      return 'browser';
    },

    async showAddToHomeAlert() {
      const alert = await alertController.create({
        cssClass: 'pwa-install-alert',
        header: this.$t('misc.installAlert.header'),
        message: this.$t('misc.installAlert.textBody', {
          appName: this.regionData
              ? '<strong>'+this.regionData['schema:name']+'</strong>'
              : this.$t('misc.installAlert.appNameFallback'),
        }),
        buttons: [
          {
            text: this.$t('misc.installAlert.dismiss'),
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              this.pwaState.alertDismissed = true;
            }
          },
          {
            text: this.$t('misc.installAlert.approve'),
            cssClass: 'primary button-native',
            handler: this.addToHome,
          },
        ],
      });

      await alert.present();
    },

    addToHome() {
      this.pwaState.deferredPrompt.prompt();
      this.pwaState.deferredPrompt.userChoice
        .then((choiceResult) => {
          if (choiceResult.outcome === 'accepted') {
            this.pwaState.deferredPrompt = null;
          }
        })
        .then(() => {
          this.pwaState.alertDismissed = true;
        });
    },

    async setLanguage(locale) {
      const alert = await alertController
        .create({
          header: this.$t('misc.changeLanguage.title'),
          message: this.$t('misc.changeLanguage.body'),
          buttons: [
            {
              text: this.$t('misc.cancel'),
              role: 'cancel',
              cssClass: 'secondary',
            },
            {
              text: 'Okay',
              handler: async () => {
                localStorage.setItem('preferredLanguage', locale);
                this.$router.go(0);
              },
            },
          ],
        });
      return alert.present();
    },

    async confirmOfflineDataRemove() {
      let alert;
      if (!this.regionId || !(await BlobContent.hasOfflineData(this.regionId))) {
        alert = await alertController
            .create({
              header: this.$t('menu.removeOfflineData'),
              message: this.$t('components.offlineMode.deleteDataAlertNoMediaBody'),
              buttons: [
                {
                  text: 'Okay',
                },
              ],
            });
      } else {
        alert = await alertController
            .create({
              header: this.$t('menu.removeOfflineData'),
              message: this.$t('components.offlineMode.deleteDataAlertBody'),
              buttons: [
                {
                  text: this.$t('misc.cancel'),
                  role: 'cancel',
                  cssClass: 'secondary',
                },
                {
                  text: 'Okay',
                  handler: async () => {
                    await BlobContent.removeBlobContent(this.regionId);
                    this.emptyAvailableOfflineMedia({regionId: this.regionId});
                  },
                },
              ],
            });
      }
      return alert.present();
    }
  },
  watch: {
    'routeCounter': {
      handler(count) {
        if ( this.getDisplayMode() !== 'standalone') {
          if (this.pwaState.deferredPrompt) {
            // awesome pwa mode
            if (count >= 2 && !this.pwaState.alertDismissed) {
              this.showAddToHomeAlert();
            }
          } else {
            // yuck...
            const platForms = getPlatforms();

            if (count >= 2 && platForms.includes('ios') && !this.pwaState.iosHintDismissed) {
              this.pwaState.showIosHint = true;
            }
            // auto dismiss after 2 page changes
            if (count > 3 && !this.pwaState.iosHintDismissed) {
              this.pwaState.showIosHint = true;
              this.pwaState.iosHintDismissed = true;
            }
          }
        }
      }
    },

    'regionData': {
      immediate: true,
      deep: true,
      handler(newData) {
        if (!newData || !newData["epapp:primaryColor"]) {
          return;
        }
        defineColorVar('primary', newData["epapp:primaryColor"]);
      }
    },

    'tourData': {
      immediate: true,
      deep: true,
      handler(newData) {
        if (!newData || !newData["epapp:primaryColor"]) {
          return;
        }
        defineColorVar('tour', newData["epapp:primaryColor"]);
      }
    }
  }

});
</script>

<style lang="scss">
/* Install prompt */
.pwa-install-alert {
  .alert-button-group {
    justify-content: space-between;
    padding: 0 0.75rem 1rem;
  }

  .primary {
    border-radius: 4px;
    background: var(--ion-color-primary);
    color: var(--ion-color-primary-contrast);
  }
}

.pwa-install-alert-ios {
  position: fixed;
  left: 0;
  bottom: 0;
  padding: 1rem;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;

  .dismiss {
    z-index: 1001;
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    font-size: 1.5rem;
  }

  .content {
    position: relative;
    padding: 1.25rem 1.5rem 1.5rem;
    border-radius: 4px;
    bottom: 0;
    background: #ffffff;
    z-index: 1;
    box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);

    .add-home {
      transform: rotate(-90deg);
      margin: 0 0.25rem;
      font-size: 1.625rem;
      position: relative;
      top: 0.375ex;
      color: #089cf5;
    }
  }

  &:after {
    z-index: 2;
    border: solid transparent;
    content: "";
    width: 24px;
    height: 24px;
    margin-top: -1rem;
    background: white;
    transform: rotate(45deg);
    transform-origin: center center;
  }
}

</style>

<style scoped lang="scss">
#main-navigation {
  --min-height: 40px;

  .navigation-block {
    padding: 0;
  }

  ion-list {
    padding: 0;

    .list-header {
      margin-bottom: 4px;
    }

    ion-label {
      margin: 0;

      &.nav-level-1 {
        font-weight: 500;
        font-size: 18px;
      }

      &.nav-level-2 {
        font-size: 16px;
      }
    }
  }

  ion-item {
    cursor: pointer;

    &::part(native) {
      min-height: 36px;
    }
  }
}



.version {
  font-size: 0.7em;
  padding-top: 8px;
  padding-bottom: 8px;
  span {
    padding-left: 3px;
    vertical-align: top;
  }
}
</style>
