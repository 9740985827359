import {createStore} from 'vuex';
import {BlobContent} from './app-db';
import router from '../router';
import { uniq } from 'lodash-es';

const LANGUAGE = (
  localStorage.getItem('preferredLanguage') || (navigator.language || '').split('-')[0] || 'en'
).toLocaleLowerCase();
const API_HOST = (/(^|.+\.)localhost$/gi).test(location.hostname) ? 'https://ep.thueringen-entdecken.de' : location.origin;

export interface AppState {
  regionId: string;
  region: any;
  tours: any;
  media: { [key: string]: string };
  availableOfflineMedia: { [key: string]: { [key: string]: string[] } };
  mediaDownloadInProgress: { [key: string]: { [key: string]: AbortController | false } };
  isOnline: boolean;
  routeCounter: number;
}

export const store = createStore({
  state() {
    return {
      regionId: '',
      region: null,
      tours: {},
      media: {},
      availableOfflineMedia: {},
      mediaDownloadInProgress: {},
      isOnline: window.navigator.onLine ?? true,
      routeCounter: 0,
    }
  },
  mutations: {
    setRegionId(state: AppState, id) {
      state.regionId = id;
    },
    setRegionData(state: AppState, params) {
      state.region = params.content;
    },
    setTourData(state: AppState, params) {
      state.tours[params.tourId] = params.content;
    },
    setMediaSource(state: AppState, params) {
      state.media[params.mediaURL] = params.source || params.mediaURL;
    },
    setIsOnline(state: AppState, isOnline) {
      state.isOnline = isOnline;
    },
    addAvailableOfflineMedia(state: AppState, params) {
      state.availableOfflineMedia[params.regionId] = state.availableOfflineMedia[params.regionId] || {};
      state.availableOfflineMedia[params.regionId][params.tourId] = (state.availableOfflineMedia[params.regionId][params.tourId] || []).concat(params.urls || []);
    },
    emptyAvailableOfflineMedia(state: AppState, params) {
      if (params.tourId) {
        delete (state.availableOfflineMedia[params.regionId] || {})[params.tourId];
      } else {
        delete state.availableOfflineMedia[params.regionId];
      }
    },
    setMediaDownloadInProgress(state: AppState, params) {
      state.mediaDownloadInProgress[params.regionId] = state.mediaDownloadInProgress[params.regionId] || {};
      state.mediaDownloadInProgress[params.regionId][params.tourId] = params.inProgress;
    },
    updateRoute(state: AppState) {
      state.routeCounter++;
    }
  },
  actions: {
    async getRegionData({dispatch, commit}, params) {
      const content: any = await fetch(`${API_HOST}/api/epapp/region?region=${params.regionId}&language=${LANGUAGE}&wbcache=true`)
        .then(async (response) => {
          const data = await response.json();
          if (!data || !data['@id']) {
            router.replace('404');
            throw new Error('NO_DATA');
          }
          return data;
        })
        .catch((e) => {
          if (e && e.message !== 'NO_DATA') {
            // networking error
            console.error(e);
            throw new Error('OFFLINE');
          }
          throw e;
        });

      // map id fields (that are different for region & tour) to common 'id' prop
      content.id = content['schema:identifier'];
      (content['epapp:tours'] || []).forEach(tour => {
        if (tour && tour['@id']) {
          tour.id = tour['@id'];
        }
      });

      await Promise.all(
        uniq((content as any)._mediaList || []).map((mediaURL) => (dispatch('setMediaSource', {mediaURL})))
      );

      commit({
        type: 'setRegionData',
        content,
      });
    },
    async getTourData({dispatch, commit}, params) {
      const content: any = await fetch(`${API_HOST}/api/epapp/tour?tour=${params.tourId}&language=${LANGUAGE}&wbcache=true`)
        .then(async (response) => {
          const data = await response.json();
          if (!data || !data['@id']) {
            router.replace('404');
            throw new Error('NO_DATA');
          }
          return data;
        })
        .catch((e) => {
          if (e && e.message !== 'NO_DATA') {
            // networking error
            console.error(e);
            throw new Error('OFFLINE');
          }
          throw e;
        });

      // map id fields (that are different for region & tour) to common 'id' prop
      content.id = content['@id'];

      await Promise.all(
        uniq((content as any)._mediaList || []).map((mediaURL) => (dispatch('setMediaSource', {mediaURL})))
      );

      commit({
        type: 'setTourData',
        tourId: params.tourId,
        content,
      });
    },
    async setMediaSource({state, commit}, params) {
      if (!state.regionId) {
        throw new Error('Tried to set media source before region id was specified!');
      }
      commit({
        type: 'setMediaSource',
        mediaURL: params.mediaURL,
        source: await BlobContent.getBlobContent(state.regionId, params.mediaURL),
      });
    }
  }
});

window.addEventListener('online', () => {
  store.commit('setIsOnline', true);
});
window.addEventListener('offline', () => {
  store.commit('setIsOnline', false);
});
